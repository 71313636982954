<template>
  <div id="ew_lomake_contact_wrapper" v-if="show">
    <div class="ew_lomake_inner_wrapper">
      <div class="close_popup" @click="closePopUp">X</div>

      <input
        type="text"
        class="ew_lomake_input"
        v-model="nimi"
        placeholder="Nimi*"
      />
      <div class="error_field" v-if="nimi_error">Virheellinen nimi!</div>

      <input
        type="text"
        class="ew_lomake_input"
        v-model="email"
        placeholder="Sähköposti*"
      />
      <div class="error_field" v-if="email_error">Virheellinen sähköposti!</div>

      <input
        type="text"
        class="ew_lomake_input"
        v-model="puhelin"
        placeholder="Puhelin"
      />
      <!--
      <div class="checkbox_wrapper">
        <input
          type="checkbox"
          class="ew_lomake_input_checkbox"
          v-model="asennuspalvelut"
        />
        <div class="asennus_label">Tarvitsetko asennuspalveluita?</div>
      </div>
      -->

      <div class="success_field" v-if="send_success">
        Lomake lähetty onnistuneesti.
      </div>
      <div class="error_field" v-if="send_error">
        Lomake lähetyksessä tapahtui virhe, ole hyvä ja kokeile myöhemmin
        uudelleen.
      </div>

      <div v-if="odottaa_lahetysta" class="submit_btn grey">Odota hetki...</div>
      <div v-else class="submit_btn" @click="sendForm">Lähetä</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Qs from "qs";

export default {
  props: ["show"],
  data() {
    return {
      nimi: "",
      email: "",
      puhelin: "",
      asennuspalvelut: true,
      nimi_error: false,
      email_error: false,
      odottaa_lahetysta: false,
      send_success: false,
      send_error: false,
    };
  },
  methods: {
    sendForm() {
      this.nimi_error = false;
      this.email_error = false;

      if (this.nimi.length < 3) {
        this.nimi_error = true;
        return;
      }

      if (!this.isEmail(this.email)) {
        this.email_error = true;
        return;
      }

      var data = {
        contact_name: this.nimi,
        contact_email: this.email,
        contact_phone: this.puhelin,
        contact_room: this.$parent.huone,
        contact_uc_laite: this.$parent.uc_laite,
        contact_langaton_kuvansiirto: this.$parent.langaton_kuvansiirto,
        contact_asennuspalvelut: this.asennuspalvelut,
      };

      var vm = this;

      var url = "https://electrowaves.fi/wp-json/contact/v1/send";
      this.odottaa_lahetysta = true;
      this.send_success = false;
      this.send_error = false;

      axios
        .post(url, Qs.stringify(data))
        .then(function (response) {
          vm.odottaa_lahetysta = false;
          vm.send_success = true;

          vm.nimi = "";
          vm.email = "";
          vm.puhelin = "";

          setTimeout(function () {
            vm.send_success = false;
          }, 3000);
        })
        .catch(function (error) {
          console.log(error);

          vm.send_error = true;

          setTimeout(function () {
            vm.send_error = false;
          }, 3000);
        });
    },
    isEmail(email) {
      var regex =
        /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      return regex.test(email);
    },
    closePopUp() {
      this.$parent.popup = false;
    },
  },
};
</script>

<style scoped lang="scss">
#ew_lomake_contact_wrapper {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 99999;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .ew_lomake_inner_wrapper {
    width: 100%;
    max-width: 500px;
    background: #fff;
    padding: 40px;
    display: flex;
    flex-direction: column;
    position: relative;

    .close_popup {
      height: 40px;
      width: 30px;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "exobold";
    }

    .ew_lomake_input {
      border: 0px;
      padding: 10px;
      background: #eee;
      border-bottom: 3px solid #00aeef;
      margin: 0 0 15px 0;
    }

    .checkbox_wrapper {
      display: flex;
      flex-direction: row;
      padding-bottom: 10px;

      .asennus_label {
        margin: 3px 0 0 5px;
        font-family: "exoregular";
        font-size: 14px;
      }
    }

    .submit_btn {
      color: #fff;
      background: #00aeef;
      padding: 15px;
      border-radius: 15px;
      text-align: center;
      cursor: pointer;
      font-family: "exoregular";
      font-size: 14px;
    }

    .submit_btn.grey {
      background: #666;
    }

    .success_field {
      padding: 5px;
      border: 2px solid #006600;
      color: #006600;
      background: #fff;
      margin: -10px 0 5px 0;
    }

    .error_field {
      padding: 5px;
      border: 2px solid #ff0000;
      color: #ff0000;
      background: #fff;
      margin: -10px 0 5px 0;
    }
  }
}
</style>
