<template>
  <div id="ew_lomake_start_wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="wb-ewl-col-md-6">
          <div
            v-if="$parent.huone == 'S'"
            class="ew_langaton_kuvan_siirto_wrapper"
          >
            <img
              class="langaton_kuvan_siirto"
              :src="$parent.assetsUrl + 'varustelutasot/S/SWLUC.jpg'"
              alt="Huone S Langaton kuvansiirto"
            />
          </div>
          <div
            v-else-if="$parent.huone == 'M'"
            class="ew_langaton_kuvan_siirto_wrapper"
          >
            <img
              class="langaton_kuvan_siirto"
              :src="$parent.assetsUrl + 'varustelutasot/M/MWLUC.jpg'"
              alt="Huone M Langaton kuvansiirto"
            />
          </div>
          <div v-else class="ew_langaton_kuvan_siirto_wrapper">
            <img
              class="langaton_kuvan_siirto"
              :src="$parent.assetsUrl + 'varustelutasot/L/LWLUC.jpg'"
              alt="Huone L Langaton kuvansiirto"
            />
          </div>
        </div>
        <div class="wb-ewl-col-md-6">
          <div id="ew_lomake_langaton_kuvan_siirto_content_wrapper">
            <h1>
              Varustellaanko neuvotteluhuone <br />videoneuvottelulaitteella?
            </h1>

            <div class="ew_lomake_btns_wrapper">
              <span class="btn orange" @click="kuvansiirtoSelection(false)"
                >Ei</span
              >
              <span class="btn green" @click="kuvansiirtoSelection(true)"
                >Kyllä</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    kuvansiirtoSelection(val) {
      this.$emit("kuvansiirto", val);
    },
  },
};
</script>

<style scoped lang="scss">
#ew_lomake_start_wrapper {
  h1 {
    font-family: "exobold";
    font-weight: 400;
    font-size: 25px;
  }
}

#ew_lomake_langaton_kuvan_siirto_content_wrapper {
  padding-top: 50px;
}

.ew_langaton_kuvan_siirto_wrapper {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  border: 2px solid #00aeef;
  background: #fff;
}

img.langaton_kuvan_siirto {
  width: 100%;
  height: auto;
  max-width: 556px;
  max-height: 293px;
}

@media (max-width: 992px) {
  #ew_lomake_langaton_kuvan_siirto_content_wrapper {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .wb-ewl-col-md-6 {
    text-align: center;
  }
}

@media (max-width: 450px) {
  #ew_lomake_langaton_kuvan_siirto_content_wrapper {
    .ew_lomake_btns_wrapper {
      display: flex;
      flex-direction: column;
    }

    .btn {
      margin-bottom: 10px;
    }
  }
}
</style>
