<template>
  <div id="ew_lomake_start_wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="wb-ewl-col-md-6">
          <div v-if="$parent.huone == 'S'" class="ew_valmis_wrapper">
            <img
              v-if="langaton_kuvansiirto"
              class="valmis"
              :src="$parent.assetsUrl + 'varustelutasot/S/SWLUC.jpg'"
              alt="Huone S Laitteet"
            />
            <img
              v-else
              class="valmis"
              :src="$parent.assetsUrl + 'varustelutasot/S/SWL.jpg'"
              alt="Huone S Laitteet"
            />
          </div>
          <div v-else-if="$parent.huone == 'M'" class="ew_valmis_wrapper">
            <img
              v-if="langaton_kuvansiirto"
              class="valmis"
              :src="$parent.assetsUrl + 'varustelutasot/M/MWLUC.jpg'"
              alt="Huone M Laitteet"
            />
            <img
              v-else
              class="valmis"
              :src="$parent.assetsUrl + 'varustelutasot/M/MWL.jpg'"
              alt="Huone M Laitteet"
            />
          </div>
          <div v-else class="ew_valmis_wrapper">
            <img
              v-if="langaton_kuvansiirto"
              class="valmis"
              :src="$parent.assetsUrl + 'varustelutasot/L/LWLUC.jpg'"
              alt="Huone L Laitteet"
            />
            <img
              v-else
              class="valmis"
              :src="$parent.assetsUrl + 'varustelutasot/L/LWL.jpg'"
              alt="Huone L Laitteet"
            />
          </div>
        </div>
        <div class="wb-ewl-col-md-6">
          <div id="ew_lomake_valmis_content_wrapper">
            <h1>Neuvotteluhuone {{ huone }}</h1>

            <div v-if="huone == 'S'">
              <ul>
                <li>Samsung QB65B</li>
                <li>LHS -seinäteline</li>
                <li>Infobit X400 (+ HX & +CX)</li>
                <li v-if="langaton_kuvansiirto">Infobit VB50 video soundbar</li>
              </ul>
            </div>

            <div v-else-if="huone == 'M'">
              <ul>
                <li>Samsung QB75B</li>
                <li>LHS -seinäteline</li>
                <li>Infobit X400 (+ HX & +CX)</li>
                <li v-if="langaton_kuvansiirto">Infobit VB40 video soundbar</li>
              </ul>
            </div>

            <div v-else>
              <ul>
                <li>Samsung QB85R-B</li>
                <li>LHS -seinäteline</li>
                <li>Infobit X400 (+ HX & +CX)</li>
                <li v-if="langaton_kuvansiirto">Infobit VB40 video soundbar</li>
              </ul>
            </div>

            <h3>Hinta yhteensä: {{ basePrice }},00€ / kk</h3>
            <h4>Kaikki hinnat €, alv. 0 % vapaasti varastossamme Espoossa.</h4>

            <div class="ew_lomake_price">
              <div
                class="btn green_b"
                @click="openPopup"
                style="text-align: center; padding: 5px 15px"
              >
                <p class="big">Ota yhteyttä!</p>
                <p class="small" style="line-height: 1.2">
                  Hintaan sisältyy laitteiden asennus. Rahoitus vaatii
                  hyväksytyn luottopäätöksen.
                </p>
              </div>

              <div class="btn orange_b" @click="backtoSquareOne">
                <p class="big">Valitse uudelleen</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import isElectron from "is-electron";

export default {
  props: ["huone", "uc_laite", "langaton_kuvansiirto"],
  data() {
    return {
      isElectron: false,
    };
  },
  mounted() {
    if (isElectron()) {
      this.isElectron = true;
    }
  },
  computed: {
    basePrice() {
      let price = 0;

      if (this.$parent.huone == "S") {
        price = 97;

        if (this.$parent.langaton_kuvansiirto) {
          price = 119;
        }
      } else if (this.$parent.huone == "M") {
        price = 115;

        if (this.$parent.langaton_kuvansiirto) {
          price = 142;
        }
      } else {
        price = 143;

        if (this.$parent.langaton_kuvansiirto) {
          price = 171;
        }
      }

      return price;
    },
  },
  methods: {
    backtoSquareOne() {
      //document.getElementById("uc_laite").style.opacity = 0;
      document.getElementById("valmis").style.opacity = 0;

      setTimeout(() => {
        document.getElementById("neukkari").style.opacity = 1;
      }, 500);

      setTimeout(() => {
        document.getElementById("valmis").style.position = "absolute";

        document.getElementById("langaton_kuvansiirto").style.position =
          "relative";
        //document.getElementById("uc_laite").style.position = "relative";
        document.getElementById("neukkari").style.position = "relative";

        document.getElementById("neukkari").style.display = "block";
        //document.getElementById("uc_laite").style.display = "none";
        document.getElementById("langaton_kuvansiirto").style.display = "none";
        document.getElementById("valmis").style.display = "none";
      }, 400);

      setTimeout(() => {
        document.getElementById("valmis").style.position = "relative";
      }, 1200);

      document.getElementById("vaihe_neukkari").style.background = "#00AEEF";
      document.getElementById("vaihe_neukkari_viiva").style.width = "0";
      //document.getElementById("vaihe_uc_viiva").style.width = "0";
      document.getElementById("vaihe_langaton_kuvansiirto_viiva").style.width =
        "0";

      setTimeout(() => {
        //document.getElementById("vaihe_uc_laite").style.background = "#C2C2C2";
        document.getElementById("vaihe_langaton_kuvansiirto").style.background =
          "#C2C2C2";
        document.getElementById("vaihe_valmis").style.background = "#C2C2C2";

        this.$parent.huone = "S";
        this.$parent.view = "neukkari";
      }, 400);
    },
    openPopup() {
      this.$parent.popup = true;
    },
  },
};
</script>

<style scoped lang="scss">
#ew_lomake_start_wrapper {
  h1 {
    font-family: "exobold";
    font-weight: 400;
    font-size: 25px;
  }
}

#ew_lomake_valmis_content_wrapper {
  padding-top: 10px;

  h3 {
    font-family: "exobold", "sans-serif";
    font-size: 19px;
  }

  h4 {
    font-family: "exoregular", "sans-serif";
    font-size: 14px;
  }

  li {
    font-family: "exoregular", "sans-serif";
    list-style-position: inside;
    margin-bottom: 5px;
  }
}

.ew_valmis_wrapper {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  border: 2px solid #00aeef;
  background: #fff;
}

img.valmis {
  width: 100%;
  height: auto;
  max-width: 556px;
  max-height: 293px;
}

@media (max-width: 992px) {
  #ew_lomake_valmis_content_wrapper {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .ew_lomake_price {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 20px;
    }

    .btn {
      margin: 0;
    }
  }

  .wb-ewl-col-md-6 {
    text-align: center;
  }
}

@media (max-width: 768px) {
  #ew_lomake_valmis_content_wrapper {
    .btn {
      margin-bottom: 10px;
    }
  }
}
</style>
