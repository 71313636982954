<template>
  <div id="ew_lomake_start_wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="wb-ewl-col-md-12">
          <h1>Valitse neukkari</h1>
        </div>

        <div class="wb-ewl-col-md-4">
          <img
            :src="$parent.assetsUrl + 'varustelutasot/S/SWL.jpg'"
            class="ew_room_img"
            alt="S-Huone"
            @click="valitseHuone('S')"
          />
          <p>S (4 henkilöä)</p>
        </div>

        <div class="wb-ewl-col-md-4">
          <img
            :src="$parent.assetsUrl + 'varustelutasot/M/MWL.jpg'"
            class="ew_room_img"
            alt="M-Huone"
            @click="valitseHuone('M')"
          />
          <p>M (8 henkilöä)</p>
        </div>

        <div class="wb-ewl-col-md-4">
          <img
            :src="$parent.assetsUrl + 'varustelutasot/L/LWL.jpg'"
            class="ew_room_img"
            alt="L-Huone"
            @click="valitseHuone('L')"
          />
          <p>L (16 henkilöä)</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    valitseHuone(val) {
      this.$emit("valitsehuone", val);
    },
  },
};
</script>

<style scoped lang="scss">
#ew_lomake_start_wrapper {
  p {
    text-align: center;
    font-family: "exoregular";
  }

  h1 {
    font-family: "exobold";
    font-weight: 400;
    font-size: 25px;
    text-align: center;
  }
}

.ew_room_img {
  border: 2px solid #ffffff;
  cursor: pointer;
  background: #fff;
  width: 100%;
}

.ew_room_img:hover {
  border: 2px solid #00aeef;
}
</style>
