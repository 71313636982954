<template>
  <div id="ew_lomake_start_wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="wb-ewl-col-md-6">
            <div v-if="$parent.huone == 'S'" class="ew_uc_laite_wrapper">
              <img class="uc_laite" :src="$parent.assetsUrl + 'varustelutasot/S/S02-S-UC.jpg'" alt="Huone S UC Laitteet">
            </div>            
            <div v-else-if="$parent.huone == 'M'" class="ew_uc_laite_wrapper">
              <img class="uc_laite" :src="$parent.assetsUrl + 'varustelutasot/M/M02-M-UC.jpg'" alt="Huone M UC Laitteet">
            </div>       
            <div v-else class="ew_uc_laite_wrapper">
              <img class="uc_laite" :src="$parent.assetsUrl + 'varustelutasot/L/L02-L-UC.jpg'" alt="Huone L UC Laitteet">
            </div>
          </div>          
          <div class="wb-ewl-col-md-6">
            <div id="ew_lomake_uc_laite_content_wrapper">
              <h1>Varustetaanko tila <br>etäneuvottelulaitteella?</h1>

              <div class="ew_lomake_btns_wrapper">
                <span class="btn orange" @click="ucSelection(false)">Ei</span> <span class="btn green" @click="ucSelection(true)">Kyllä</span>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    methods: {
      ucSelection(val) {
        this.$emit('uclaite', val)
      }
    }
}
</script>

<style scoped lang="scss">
  #ew_lomake_start_wrapper {
    h1 {
      font-family: 'exobold';
      font-weight: 400;
      font-size: 25px;
    }
  }

  #ew_lomake_uc_laite_content_wrapper {
    padding-top: 50px;
  }

  .ew_uc_laite_wrapper {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    display: inline-block;
    border: 2px solid #00AEEF;
    background: #fff;
  }

  img.uc_laite {
    width: 100%;
    height: auto;
    max-width: 556px;
    max-height: 293px;
  }

  @media(max-width: 992px) {
    #ew_lomake_uc_laite_content_wrapper {
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .wb-ewl-col-md-6 {
        text-align: center;
    }
  }

  @media(max-width: 450px) {
    #ew_lomake_uc_laite_content_wrapper {
      .ew_lomake_btns_wrapper {
        display: flex;
        flex-direction: column;
      }

      .btn {
        margin-bottom: 10px;
      }
    }
  }
</style>
