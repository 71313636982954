<template>
  <div id="ew_lomake_main_wrapper" class="ew_lomake_main_wrapper">
    <div class="ew_lomake_header_wrapper">
      <div class="ew_lomake_header_left">
        <!-- <img :src="assetsUrl + 'logo_31.svg'" @error="imageLoadError" width="136" height="59" alt="Electro Waves logo"> -->
      </div>

      <div class="ew_lomake_header_middle">
        <h1>Neuvotteluhuone langattomalla kuvansiirrolla</h1>
      </div>

      <div class="ew_lomake_header_right"></div>
    </div>

    <div id="ew_lomake_steps_wrapper" class="ew_lomake_steps_wrapper">
      <div class="ew_lomake_step">
        <div id="ew_lomake_vaihe_wrap_step1" class="ew_lomake_vaihe_wrap">
          <div class="ew_vaihe_circle">
            <div id="vaihe_neukkari" class="ew_vaihe_inner_circle">
              <img
                @error="imageLoadError"
                :src="assetsUrl + 'check.svg'"
                width="28.79"
                height="28.79"
                alt="Check"
              />
            </div>
          </div>

          <div class="ew_vaihe_viiva">
            <div class="ew_vaihe_outer_viiva">
              <div id="vaihe_neukkari_viiva" class="ew_vaihe_inner_viiva"></div>
            </div>
          </div>
        </div>
        <div class="ew_lomake_vaihe_content_wrap">
          <h3>Vaihe 1</h3>
          <h2>Valitse neukkari</h2>
        </div>
      </div>

      <!--

      <div class="ew_lomake_step">
        <div id="ew_lomake_vaihe_wrap_step2" class="ew_lomake_vaihe_wrap">
          <div class="ew_vaihe_circle">
            <div id="vaihe_uc_laite" class="ew_vaihe_inner_circle">
              <img
                :src="assetsUrl + 'check.svg'"
                width="28.79"
                height="28.79"
                alt="Check"
              />
            </div>
          </div>

          <div class="ew_vaihe_viiva">
            <div class="ew_vaihe_outer_viiva">
              <div id="vaihe_uc_viiva" class="ew_vaihe_inner_viiva"></div>
            </div>
          </div>
        </div>

        <div class="ew_lomake_vaihe_content_wrap">
          <h3>Vaihe 2</h3>
          <h2>Varustetaanko tila <br />etäneuvottelulaitteella?</h2>
        </div>
      </div>

     -->

      <div class="ew_lomake_step">
        <div id="ew_lomake_vaihe_wrap_step3" class="ew_lomake_vaihe_wrap">
          <div class="ew_vaihe_circle">
            <div id="vaihe_langaton_kuvansiirto" class="ew_vaihe_inner_circle">
              <img
                :src="assetsUrl + 'check.svg'"
                width="28.79"
                height="28.79"
                alt="Check"
              />
            </div>
          </div>

          <div class="ew_vaihe_viiva">
            <div class="ew_vaihe_outer_viiva">
              <div
                id="vaihe_langaton_kuvansiirto_viiva"
                class="ew_vaihe_inner_viiva"
              ></div>
            </div>
          </div>
        </div>

        <div class="ew_lomake_vaihe_content_wrap">
          <h3>Vaihe 2</h3>
          <h2>
            Varustellaanko neuvotteluhuone <br />videoneuvottelulaitteella?
          </h2>
        </div>
      </div>

      <div class="ew_lomake_step">
        <div id="ew_lomake_vaihe_wrap_step4" class="ew_lomake_vaihe_wrap">
          <div class="ew_vaihe_circle">
            <div id="vaihe_valmis" class="ew_vaihe_inner_circle">
              <img
                :src="assetsUrl + 'check.svg'"
                width="28.79"
                height="28.79"
                alt="Check"
              />
            </div>
          </div>
        </div>

        <div class="ew_lomake_vaihe_content_wrap">
          <h3>Vaihe 3</h3>
          <h2>Valmis</h2>
        </div>
      </div>
    </div>

    <div class="ew_lomake_content">
      <div id="neukkari" class="anim_item">
        <Neukkari @valitsehuone="vaihdaNeukkari" />
      </div>

      <!--
      <div id="uc_laite" class="anim_item">
        <UcLaite @uclaite="vaihdaUcLaite" />
      </div>
-->
      <div id="langaton_kuvansiirto" class="anim_item">
        <LangatonKuvanSiirto @kuvansiirto="vaihdaKuvansiirto" :huone="huone" />
      </div>

      <div id="valmis" class="anim_item">
        <Valmis
          :huone="huone"
          :uc_laite="uc_laite"
          :langaton_kuvansiirto="langaton_kuvansiirto"
        />
      </div>
    </div>

    <ContactPopUp :show="popup" />
  </div>
</template>

<script>
import Neukkari from "./components/Neukkari.vue";
import UcLaite from "./components/UcLaite.vue";
import LangatonKuvanSiirto from "./components/LangatonKuvanSiirto.vue";
import Valmis from "./components/Valmis.vue";
import ContactPopUp from "./components/ContactPopUp.vue";

export default {
  name: "EW_Lomake",
  components: {
    Neukkari,
    UcLaite,
    LangatonKuvanSiirto,
    Valmis,
    ContactPopUp,
  },
  data() {
    return {
      view: "neukkari",
      huone: "S",
      uc_laite: false,
      langaton_kuvansiirto: false,
      assetsUrl: "/app/assets/img/",
      //assetsUrl: 'https://electrowaves.fi/app/assets/img/', // External URL mistä ladataan
      popup: false,
    };
  },
  methods: {
    imageLoadError() {
      this.assetsUrl = "/assets/img/";
    },
    vaihdaNeukkari(val) {
      /*
  huone.value = val;
  view.value = "uc_laite";

  document.getElementById("neukkari").style.opacity = 0;
  document.getElementById("uc_laite").style.opacity = 0;

  document.getElementById("neukkari").style.display = "none";
  document.getElementById("uc_laite").style.display = "none";

  setTimeout(() => {
    document.getElementById("uc_laite").style.opacity = 1;
  }, 500);

  setTimeout(() => {
    document.getElementById("neukkari").style.position = "absolute";
    document.getElementById("uc_laite").style.display = "block";
  }, 400);

  document.getElementById("vaihe_neukkari").style.background = "#21C174";
  document.getElementById("vaihe_neukkari_viiva").style.width = "100%";

  setTimeout(() => {
    document.getElementById("vaihe_uc_laite").style.background = "#00AEEF";
    document.getElementById("vaihe_uc_laite").style.transform = "scale(1.1)";
  }, 400);

  setTimeout(() => {
    document.getElementById("vaihe_uc_laite").style.transform = "scale(1.0)";
  }, 1200);
  */

      this.huone = val;
      this.view = "langaton_kuvansiirto";

      document.getElementById("neukkari").style.opacity = 0;
      document.getElementById("langaton_kuvansiirto").style.opacity = 0;

      document.getElementById("neukkari").style.display = "none";
      document.getElementById("langaton_kuvansiirto").style.display = "none";

      setTimeout(() => {
        document.getElementById("langaton_kuvansiirto").style.opacity = 1;
      }, 500);

      setTimeout(() => {
        document.getElementById("neukkari").style.position = "absolute";
        document.getElementById("langaton_kuvansiirto").style.display = "block";
      }, 400);

      document.getElementById("vaihe_neukkari").style.background = "#21C174";
      document.getElementById("vaihe_neukkari_viiva").style.width = "100%";

      setTimeout(() => {
        document.getElementById("vaihe_langaton_kuvansiirto").style.background =
          "#00AEEF";
        document.getElementById("vaihe_langaton_kuvansiirto").style.transform =
          "scale(1.1)";
      }, 400);

      setTimeout(() => {
        document.getElementById("vaihe_langaton_kuvansiirto").style.transform =
          "scale(1.0)";
      }, 1200);
    },
    /*
    vaihdaUcLaite(val) {
      this.uc_laite = val;
      this.view = "langaton_kuvansiirto";

      document.getElementById("uc_laite").style.opacity = 0;
      document.getElementById("langaton_kuvansiirto").style.opacity = 0;

      document.getElementById("uc_laite").style.display = "none";
      document.getElementById("langaton_kuvansiirto").style.display = "none";

      setTimeout(() => {
        document.getElementById("langaton_kuvansiirto").style.opacity = 1;
      }, 500);

      setTimeout(() => {
        document.getElementById("uc_laite").style.position = "absolute";
        document.getElementById("langaton_kuvansiirto").style.display = "block";
      }, 400);

      document.getElementById("vaihe_uc_laite").style.background = "#21C174";
      document.getElementById("vaihe_uc_viiva").style.width = "100%";

      setTimeout(() => {
        document.getElementById("vaihe_langaton_kuvansiirto").style.background =
          "#00AEEF";
        document.getElementById("vaihe_langaton_kuvansiirto").style.transform =
          "scale(1.1)";
      }, 400);

      setTimeout(() => {
        document.getElementById("vaihe_langaton_kuvansiirto").style.transform =
          "scale(1.0)";
      }, 1200);
    },
    */
    vaihdaKuvansiirto(val) {
      this.langaton_kuvansiirto = val;
      this.view = "valmis";

      document.getElementById("langaton_kuvansiirto").style.opacity = 0;
      document.getElementById("valmis").style.opacity = 0;

      document.getElementById("langaton_kuvansiirto").style.display = "none";
      document.getElementById("valmis").style.display = "none";

      setTimeout(() => {
        document.getElementById("valmis").style.opacity = 1;
      }, 500);

      setTimeout(() => {
        document.getElementById("langaton_kuvansiirto").style.position =
          "absolute";
        document.getElementById("valmis").style.display = "block";
      }, 400);

      document.getElementById("vaihe_langaton_kuvansiirto").style.background =
        "#21C174";
      document.getElementById("vaihe_langaton_kuvansiirto_viiva").style.width =
        "100%";

      setTimeout(() => {
        document.getElementById("vaihe_valmis").style.background = "#00AEEF";
        document.getElementById("vaihe_valmis").style.transform = "scale(1.1)";
      }, 400);

      setTimeout(() => {
        document.getElementById("vaihe_valmis").style.transform = "scale(1.0)";
      }, 400);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./assets/css/style.min.css";

@font-face {
  font-family: "exoregular";
  src: url("./assets/fonts/exo-regular-webfont.eot");
  src: url("./assets/fonts/exo-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/exo-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/exo-regular-webfont.woff") format("woff"),
    url("./assets/fonts/exo-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "exobold";
  src: url("./assets/fonts/exo-bold-webfont.eot");
  src: url("./assets/fonts/exo-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/exo-bold-webfont.woff2") format("woff2"),
    url("./assets/fonts/exo-bold-webfont.woff") format("woff"),
    url("./assets/fonts/exo-bold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.ew_lomake_main_wrapper {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  overflow: hidden;
}

.anim_item {
  opacity: 0;
  transition: 0.5s;
  display: none;
}

#neukkari {
  opacity: 1;
  display: block;
}

#ew_lomake_main_wrapper {
  padding: 100px;
  min-height: 770px;

  h1 {
    font-family: "exobold";
    font-weight: 600;
    font-size: 35px;
  }

  h2 {
    font-family: "exoregular";
    font-weight: 600;
    font-size: 15px;
    color: #141414;
    margin: 10px 0 0 0;
    padding: 0;
  }

  h3 {
    font-family: "exoregular";
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    color: #8e9197;
    margin: 10px 0 0 0;
    padding: 0;
  }

  h4 {
    font-family: "exoregular";
    font-weight: 400;
    font-size: 14px;
  }

  .ew_lomake_header_wrapper {
    display: flex;
    margin-bottom: 35px;
  }

  .ew_lomake_header_left {
    flex: 1;
  }

  .ew_lomake_header_middle {
    flex: 2;
    text-align: center;
  }

  .ew_lomake_header_right {
    flex: 1;
  }

  #ew_lomake_steps_wrapper {
    display: flex;
    margin: 0 auto 60px auto;
    max-width: 1000px;
  }

  .ew_lomake_step {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex: 1 0 48%;
  }

  .ew_lomake_vaihe_content_wrap {
    display: flex;
    flex-direction: column;
  }

  .ew_lomake_vaihe_wrap {
    display: flex;
  }

  .ew_vaihe_circle {
    display: flex;
    flex: 1 0 20%;
    height: 49px;
    float: left;
  }

  .ew_vaihe_inner_circle {
    background: #c2c2c2;
    color: #fff;
    border-radius: 500px;
    width: 49px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
  }

  #vaihe_neukkari {
    background: #00aeef;
  }

  .ew_vaihe_viiva {
    display: flex;
    flex: 4 0 50%;
    height: 49px;
    align-items: center;
    padding-right: 40px;
  }

  .ew_vaihe_outer_viiva {
    height: 2px;
    width: 100%;
    background: #c2c2c2;
  }

  .ew_vaihe_inner_viiva {
    height: 2px;
    width: 0;
    background: #21c174;
    transition: 0.5s;
  }
}

#ew_lomake_valmis_content_wrapper h4 {
  font-size: 13px;
  line-height: 23px;
}

@media (max-width: 1050px) {
  .btn.orange_b,
  .btn.green_b {
    width: 100% !important;
    margin-bottom: 5px;
  }
}

@media (max-width: 992px) {
  .ew_lomake_header_wrapper {
    flex-direction: column;
  }

  .ew_lomake_header_left {
    text-align: center;
  }
}

@media (max-width: 768px) {
  #ew_lomake_main_wrapper {
    padding: 20px;
    min-height: auto;

    #ew_lomake_steps_wrapper {
      max-width: 580px;
    }
  }
}

@media (max-width: 600px) {
  #ew_lomake_main_wrapper {
    .ew_vaihe_viiva {
      display: none;
    }

    .ew_lomake_vaihe_content_wrap {
      h2 {
        display: none;
      }

      h3 {
        padding-left: 2px;
      }
    }
  }
}

@media (max-width: 450px) {
  #ew_lomake_main_wrapper {
    padding: 25px 5px;

    h1 {
      font-size: 21px;
    }

    .ew_lomake_step {
      display: flex;
      flex-direction: column;
      flex: 1 0 25%;
      align-items: center;
    }
  }
}
</style>
